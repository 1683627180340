import React, { useGlobal } from 'reactn';
import { withApollo, Query } from 'react-apollo';
import { Col, Row, Table } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Loading from "../../../../components/Loading/Loading";
import ErrorPage from '../../../../components/ErrorPage';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {
  gasDashboard,
  insertReportActionMutation,
} from '../../../../queries/Queries';
import { cdnDownloadFormatterName } from '../../../../utils/Formatters';
import { ReportActions } from "../../../../utils/Helpers";

const GasInvoices = ({ client }) => {
  const [username, setUsername] = useGlobal('username');

  const handleRecalculate = (reportId, action) => {
    client.mutate({
      variables: { report_id: reportId, action: action, createdBy: username },
      mutation: insertReportActionMutation,
      refetchQueries: [{ query: gasDashboard }],
    });
  };

  const handleApprove = (reportId, action) => {
    client.mutate({
      variables: { report_id: reportId, action: action, createdBy: username },
      mutation: insertReportActionMutation,
      refetchQueries: [{ query: gasDashboard }],
    });
  };

  const expandRow = {
    renderer: (row) => (
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Invoice Amount</th>
                <th>Calculated Amount</th>
                <th>Delta</th>
              </tr>
            </thead>
            <tbody>
              {row.rates_tab.map((item, index) => (
                <tr key={index}>
                  <td>{item.Name}</td>
                  <td>{item['Invoice Amount'] || 0}</td>
                  <td>{item['Calculated Amount'] || 0}</td>
                  <td>{item.Delta || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  return (
    <div className='organization'>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Gas Invoices</h1>
        </Col>
      </Row>
      <Query query={gasDashboard}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorPage />;

          const reviewColumns = [
            {
              dataField: 'invoice_type',
              text: 'Invoice Type',
              sort: true,
            },
            {
              dataField: 'invoice_period',
              text: 'Period',
              formatter: (cell) => moment(cell).format('MMM-YY'),
              sort: true,
              sortValue: (cell) => moment(cell).valueOf(),
            },
            {
              dataField: 'issue_date',
              text: 'Issue Date',
              formatter: (cell) => moment(cell).format('DD/MM/YYYY'),
              sort: true,
              sortValue: (cell) => moment(cell).valueOf(),
            },
            {
              dataField: 'due_date',
              text: 'Due Date',
              formatter: (cell) => moment(cell).format('DD/MM/YYYY'),
              sort: true,
              sortValue: (cell) => moment(cell).valueOf(),
            },
            {
              dataField: 'total_invoiced',
              text: 'Invoice Amount',
              sort: true,
            },
            {
              dataField: 'total_calculated',
              text: 'Calculated Amount',
              sort: true,
            },
            {
              dataField: 'delta',
              text: 'Delta',
              sort: true,
            },
            {
              dataField: 'export',
              text: 'export',
              formatter: (cell, row) => {
                return (
                  <Row className='justify-content-around'>
                    {cdnDownloadFormatterName(row.export_link, null, '2.7em')}
                  </Row>
                );
              },
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
            {
              dataField: 'recalculation',
              text: 'recalculation',
              formatter: (cell, row) => {
                return row.reportstatus === 'In Review' ? (
                  <Row className='justify-content-around'>
                    <i
                      style={{
                        cursor: 'pointer',
                      }}
                      className={'icon ion-android-refresh'}
                      onClick={() =>
                        handleRecalculate(row.id, ReportActions.Cancel)
                      }
                    ></i>
                  </Row>
                ) : null;
              },
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
            {
              dataField: 'approve',
              text: 'approve',
              formatter: (cell, row) => {
                return row.reportstatus === 'In Review' ? (
                  <Row className='justify-content-around'>
                    <i
                      style={{
                        cursor: 'pointer',
                      }}
                      className={'icon ion-android-send'}
                      onClick={() =>
                        handleApprove(row.id, ReportActions.Approve)
                      }
                    ></i>
                  </Row>
                ) : null;
              },
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
            {
              dataField: 'reportstatus',
              text: 'Status',
              sort: true,
            },
            {
              dataField: 'approved_by',
              text: 'Approved By',
              sort: true,
              headerStyle: { textAlign: 'center' },
              style: { textAlign: 'center' },
            },
          ];

          const approvedColumns = reviewColumns.filter(
            (column) =>
              column.dataField !== 'recalculation' &&
              column.dataField !== 'approve'
          );

          const approvedData = [];
          const inReviewData = [];
          data.gas_dashboard.forEach((item) => {
            if (item.reportstatus === 'Sent') {
              approvedData.push(item);
            } else if (item.reportstatus === 'Sending') {
              approvedData.push({ ...item, reportstatus: 'Approved' });
            } else if (item.reportstatus === 'Review') {
              inReviewData.push({
                ...item,
                reportstatus: 'In Review',
              });
            } else if (item.reportstatus === 'Recalculating') {
              inReviewData.push(item);
            }
          });
          return (
            <div className='page-cnt'>
              <Row>
                <Col>
                  <h3>Gas Invoices In Review</h3>
                </Col>
                <Col className='col-12'>
                  <BootstrapTable
                    classes='table-hover'
                    wrapperClasses='table-responsive'
                    noDataIndication={() => <div>No records in table.</div>}
                    columns={reviewColumns}
                    data={inReviewData || []}
                    expandRow={expandRow}
                    keyField={'id'}
                    loading={<Loading />}
                    bordered={false}
                    hover
                    pagination={paginationFactory({
                      page: 1,
                      sizePerPage: 10,
                      totalSize: inReviewData.length,
                      sizePerPageList: [
                        { text: '5', value: 5 },
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                      ],
                    })}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='mt-4'>
                  <h3>Approved Gas Invoices</h3>
                </Col>
                <Col className='col-12'>
                  <BootstrapTable
                    classes='table-hover'
                    wrapperClasses='table-responsive'
                    noDataIndication={() => <div>No records in table.</div>}
                    columns={approvedColumns}
                    data={approvedData || []}
                    expandRow={expandRow}
                    keyField={'id'}
                    loading={<Loading />}
                    bordered={false}
                    hover
                    pagination={paginationFactory({
                      page: 1,
                      sizePerPage: 10,
                      totalSize: approvedData.length,
                      sizePerPageList: [
                        { text: '5', value: 5 },
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                      ],
                    })}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default withApollo(GasInvoices);
