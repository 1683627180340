import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import i18n from "../Login/i18n";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { renderInput } from "../../../RenderInput";
import { isValidEmail } from "../../../utils/Helpers";

// Updated API_URL construction to use the core URL from config
const API_URL =
  window.config && window.config.consul && window.config.consul.CORE_URL
    ? window.config.consul.CORE_URL
    : typeof CORE_URL !== "undefined"
    ? CORE_URL
    : "";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = i18n.t("emailRegistration.required");
  } else if (!isValidEmail(values.email)) {
    errors.email = i18n.t("emailRegistration.invalidEmail");
  }
  return errors;
};

const EmailRegistration = (props) => {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleFormSubmit = async (values) => {
    setError("");
    setSuccessMessage("");

    try {
      const response = await axios({
        method: "put",
        url: `${API_URL}/register?username=${encodeURIComponent(values.email)}`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response && response.data) {
        const responseData = response.data;
        if (
          responseData.includes &&
          responseData.includes("User registered successfully")
        ) {
          setSuccessMessage(i18n.t("emailRegistration.checkEmail"));
          return;
        }
      }
    } catch (error) {
      const errorResponse = error.response || {};
      const errorMessage = errorResponse.data || "";

      if (
        errorResponse.status === 404 &&
        errorMessage.includes &&
        errorMessage.includes("User with username")
      ) {
        setSuccessMessage(i18n.t("emailRegistration.proceedToRegistration"));
        return;
      }

      if (
        errorResponse.status === 400 &&
        errorMessage.includes &&
        errorMessage.includes("User already registered")
      ) {
        setError(i18n.t("emailRegistration.alreadyRegistered"));
        return;
      }

      if (
        errorResponse.status === 400 &&
        errorMessage.includes &&
        errorMessage.includes("No data provided")
      ) {
        if (props.history) {
          props.history.replace({
            pathname: "/new-user-registration",
            state: { email: values.email },
          });
        }
        return;
      }

      setError(i18n.t("emailRegistration.genericError"));
      console.error("Registration error:", error);
    }
  };

  const { handleSubmit, invalid, submitting } = props;

  return (
    <div className='app flex-row align-items-center'>
      <Container fluid={true}>
        <Row className='justify-content-center'>
          <Col md='12' style={{ paddingLeft: 0, paddingRight: 0 }}>
            <CardGroup>
              <Card className='p-4 login-panel' style={{ height: "auto" }}>
                <CardBody className='credentials-card'>
                  <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='login-logo'></div>
                    <h1>{i18n.t("emailRegistration.title")}</h1>

                    <div
                      className='message-container'
                      style={{
                        maxWidth: "320px",
                      }}
                    >
                      {error ? (
                        <p className='text-danger login-err text-center'>
                          {error}
                        </p>
                      ) : successMessage ? (
                        <p className='text-success login-success text-center'>
                          {successMessage}
                        </p>
                      ) : (
                        <p className='signin-text-muted text-center'>
                          {i18n.t("emailRegistration.subtitle")}
                        </p>
                      )}
                    </div>

                    <FormGroup>
                      <Label htmlFor='email' className='label-name'>
                        {i18n.t("emailRegistration.emailAddress")}*
                      </Label>
                      <Field
                        name='email'
                        component={renderInput}
                        type='email'
                        inputGroup={{ className: "mb-3" }}
                        placeholder={i18n.t(
                          "emailRegistration.emailPlaceholder"
                        )}
                        onChange={() => {
                          setError("");
                          setSuccessMessage("");
                        }}
                      />
                    </FormGroup>

                    <Row>
                      <Col xs='12' className='mt-2'>
                        <Button
                          color='primary'
                          className='px-4 sign-in w-100'
                          disabled={invalid || submitting}
                          block
                        >
                          {submitting && (
                            <span>
                              <i className='fa fa-cog fa-spin'></i>{" "}
                            </span>
                          )}
                          {i18n.t("emailRegistration.submit")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const EmailRegistrationForm = reduxForm({
  form: "emailRegistration",
  validate,
})(EmailRegistration);

export default React.forwardRef((props, ref) => (
  <EmailRegistrationForm {...props} />
));
