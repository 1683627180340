import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Form from "react-jsonschema-form";
import axios from "../../utils/Client";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import i18n from "../../views/Pages/Login/i18n";
import Loading from "../../components/Loading";

const GRAPHQL_URL =
  window.config.consul.GRAPHQL_URL ||
  (typeof GRAPHQL_URL !== "undefined" ? GRAPHQL_URL : "");
const CORE_URL =
  window.config.consul.CORE_URL ||
  (typeof CORE_URL !== "undefined" ? CORE_URL : "");

const schema = {
  type: "object",
  required: ["password", "passwordConfirm", "termsAccepted"],
  properties: {
    userName: {
      type: "string",
      title: i18n.t("register.username"),
    },
    password: {
      type: "string",
      title: i18n.t("register.password"),
      description: i18n.t("register.passwordPolicy"),
    },
    passwordConfirm: {
      type: "string",
      title: i18n.t("register.passwordConfirmation"),
    },
    termsAccepted: {
      type: "boolean",
      title: i18n.t("register.acceptTermsLabel"),
    },
  },
};

// Custom checkbox component
const CustomCheckbox = function (props) {
  return (
    <div className='form-group'>
      <div className='checkbox-wrapper'>
        <input
          type='checkbox'
          id='termsAccepted'
          className='css-checkbox'
          style={{ display: "none" }}
          checked={props.value}
          onChange={(event) => props.onChange(event.target.checked)}
        />
        <label className='css-label' htmlFor='termsAccepted'>
          <i className='icon ion-android-checkbox'></i>
          {i18n.t("register.acceptTermsLabel")}{" "}
          <a
            href={
              (window.config &&
                window.config.consul &&
                window.config.consul.TERMS_URL) ||
              "/terms-and-conditions"
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            {i18n.t("register.termsAndConditions")}
          </a>
        </label>
        {props.required && !props.value && (
          <div className='text-danger'>{i18n.t("register.acceptTerms")}</div>
        )}
      </div>
    </div>
  );
};

const format =
  /^(?=.*[0-9]+)(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+).{8,}$/;

const uiSchema = {
  userName: {
    "ui:readonly": true,
  },
  password: {
    "ui:widget": "password",
  },
  passwordConfirm: {
    "ui:widget": "password",
  },
  termsAccepted: {
    "ui:widget": CustomCheckbox,
  },
};

const validate = (formData, errors) => {
  if (formData.password !== formData.passwordConfirm) {
    if (errors)
      errors.passwordConfirm.addError(
        i18n.t("register.passwordConfirmationErr")
      );
  }
  if (!format.test(formData.password)) {
    if (errors) errors.password.addError(i18n.t("register.passwordPolicy"));
  }
  if (!formData.termsAccepted) {
    if (errors) errors.termsAccepted.addError(i18n.t("register.acceptTerms"));
  }
  return errors;
};

const validatePassword = (formData) => {
  return !(
    formData.password !== formData.passwordConfirm ||
    !format.test(formData.password) ||
    !formData.termsAccepted
  );
};

const RegisterUser = ({ match, history }) => {
  const token = match.params.token;
  const [data, setData] = useState({ termsAccepted: false });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${GRAPHQL_URL}/oauth/spring-security-oauth-server/register/` + token,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (response.status === 200) {
          setData({
            userName: response.data.username,
            termsAccepted: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const translate = (obj) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      if (!obj.props) {
        for (let [key, value] of Object.entries(obj)) {
          if (typeof value === "object") {
            translate(value);
          } else if (typeof value === "array") {
            if (key === "enumNames") {
              obj[key] = value.map((e) => i18n.t(e));
            } else {
              obj[key] = value.map((e) => translate(e));
            }
          } else if (typeof value === "string") {
            if (key === "title" || key === "description")
              obj[key] = i18n.t(obj[key]);
          }
        }
      }
    } else if (obj && Array.isArray(obj)) {
      obj.forEach((value, key) => {
        if (typeof value === "object" && !Array.isArray(value)) {
          translate(value);
        } else if (Array.isArray(value)) {
          if (key === "enumNames") {
            obj[key] = value.map((e) => i18n.t(e));
          } else {
            obj[key] = value.map((e) => translate(e));
          }
        } else if (typeof value === "string") {
          if (key === "title" || key === "description")
            obj[key] = i18n.t(obj[key]);
        }
      });
    }
    return obj;
  };

  const onSubmit = () => {
    if (!data.termsAccepted) {
      return;
    }

    const user = {
      username: data.userName,
      password: data.password,
      enabled: true,
      locked: false,
    };

    if (data.password && data.password !== "" && validatePassword(data)) {
      axios
        .post(
          `${GRAPHQL_URL}/oauth/spring-security-oauth-server/register/` + token,
          user
        )
        .then((response) => {
          loginUser(data);
        });
    }
  };

  const loginUser = (data) => {
    axios
      .post(`${CORE_URL}/login`, {
        userName: data.userName,
        password: data.password,
      })
      .then(function (response) {
        let expDate = new Date();
        expDate.setTime(response.data.expirationDate);
        document.cookie =
          "token=true;expires=" + expDate.toUTCString() + ";path=/";
        localStorage.token = response.data.token;
        history.push("/");
      })
      .catch((error) => {
        console.log("login error", error);
      });
  };

  const onChange = ({ formData }) => {
    setData(formData);
  };

  const mainClasses = "app flex-row align-items-center";
  const translated = translate(schema);

  if (isLoading) {
    return <Loading />;
  } else if (data.userName) {
    return (
      <div>
        <div className='registration-logo'></div>
        <div className={mainClasses}>
          <div className='app-body create-user'>
            <Container fluid={true} className='login'>
              <Row className='justify-content-center'>
                <Col md='8' style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Row className='subheader'>
                    <Col className='col-8'>
                      <h1>{i18n.t("header.registerUsers")}</h1>
                    </Col>
                  </Row>
                  <Row className='page-cnt'>
                    <Col className='col-8'>
                      <Form
                        schema={translated}
                        uiSchema={uiSchema}
                        validate={validate}
                        formData={data}
                        onChange={onChange}
                      >
                        <div className='buttons'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={onSubmit}
                            disabled={!data.termsAccepted}
                          >
                            {i18n.t("header.register")}
                          </button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className='registration-logo'></div>
        <Row className='subheader'>
          <Col className='col-12'>
            <h1>{i18n.t("header.invalidRequest")}</h1>
          </Col>
        </Row>
      </div>
    );
  }
};

export default withApollo(withRouter(RegisterUser));
