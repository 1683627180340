import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

/**
 * Utility function to determine if registration is enabled.
 * Handles both boolean and string "true" values.
 *
 * @returns {boolean} True if registration is enabled, else false.
 */
const isRegistrationEnabled = () => {
  const regEnabled = window.config.consul.REGISTRATION_ENABLED;

  // Handle boolean true
  if (regEnabled === true) {
    return true;
  }

  // Handle string "true" (case-insensitive)
  if (typeof regEnabled === "string") {
    return regEnabled.toLowerCase() === "true";
  }

  // Handle numeric representations if applicable (e.g., 1)
  if (typeof regEnabled === "number") {
    return regEnabled === 1;
  }

  // Default to false for any other values
  return false;
};

/**
 * Component that protects route from unauthorized users and handles registration access.
 */
class LoginRoute extends Component {
  static propTypes = {
    canAccess: PropTypes.func.isRequired,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    path: PropTypes.string.isRequired,
    name: PropTypes.string,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    requiresRegistration: PropTypes.bool,
    render: PropTypes.func, // Added for render prop
  };

  checkAccess = () => {
    const { canAccess, requiresRegistration, ...rest } = this.props;

    // If the route requires registration, check if it's enabled
    if (requiresRegistration) {
      if (!isRegistrationEnabled()) {
        return <Redirect to='/login' />;
      }
    }

    // If the user can access, redirect to home; else, render the route
    return canAccess() ? <Redirect to='/' /> : <Route {...rest} />;
  };

  render() {
    return this.checkAccess();
  }
}

export default LoginRoute;
