import React, { useEffect, useState, useGlobal } from "reactn";
import ReactDOM from "react-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { withApollo } from "react-apollo";
import { CSVLink } from "react-csv";
import {
  weeklyValidationQuery,
  insertGasReadingsAction,
} from "../../../../queries/Queries";
import Loading from "../../../../components/Loading";
import ExpiringAlert from "../../../../components/ExpiringAlert/ExpiringAlert";
import BulkApproveConfirmModal from "../../../../views/Clients/Hartree/BulkApproveConfirmModal/BulkApproveConfirmModal";
import { columns, READ_STATUS } from "./columns";
import { ReportActions } from "../../../../utils/Helpers";

const insertGasReadingsActions = async (client, readings) => {
  await client.mutate({
    mutation: insertGasReadingsAction,
    variables: {objects: readings} ,
  });

  await client.query({
    query: weeklyValidationQuery,
    fetchPolicy: "network-only",
  });
}

const CustomSelectionHeader = ({
  checked,
  indeterminate,
  onAllRowsSelect,
}) => (
  <div
    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
  >
    <div>Select All</div>
    <div className='checkbox-wrapper'>
      <input
        type='checkbox'
        className='css-checkbox'
        checked={checked}
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        onChange={onAllRowsSelect}
      />
      <i
        className={`icon ${
          checked
            ? "ion-android-checkbox"
            : "ion-android-checkbox-outline-blank"
        }`}
        style={{ fontSize: "20px", cursor: "pointer" }}
      />
    </div>
  </div>
);

const SelectionCell = ({ row, checked, disabled, onChange }) => (
  <div className='checkbox-wrapper'>
    <input
      type='checkbox'
      className='css-checkbox'
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <i
      className={`icon ${
        checked ? "ion-android-checkbox" : "ion-android-checkbox-outline-blank"
      }`}
      style={{
        fontSize: "20px",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    />
  </div>
);

const WeeklyValidation = ({ client }) => {
  const [username, setUsername] = useGlobal('username');
  const [weeklyValidationData, setWeeklyValidationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSizePerPage, setCurrentSizePerPage] = useState(50);
  const [mutationPerformed, setMutationPerformed] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: weeklyValidationQuery,
        fetchPolicy: "no-cache",
      })
      .then((res) =>
        setWeeklyValidationData(
          res.data.gas_readings_latest.map((reading) => ({
            ...reading,
            id: reading.id,
          }))
        )
      )
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
        setMutationPerformed(false);
      });
  }, [client, mutationPerformed]);

  useEffect(() => {
    let data = [];
    const dateFilter = (date) =>
      moment(date).isBetween(
        moment(dateRange.startDate).format("MM/DD/YYYY"),
        moment(dateRange.endDate),
        undefined,
        "[]"
      );

    if (searchTerm.length > 0 && dateRange.startDate && dateRange.endDate) {
      data = weeklyValidationData.filter(
        (el) =>
          (el.mprn.includes(searchTerm) && dateFilter(el.date)) ||
          (el.site.includes(searchTerm) && dateFilter(el.date))
      );
    } else if (searchTerm.length > 0) {
      data = weeklyValidationData.filter(
        (el) =>
          el.mprn.includes(searchTerm) ||
          el.site.trim().toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (dateRange.startDate && dateRange.endDate) {
      data = weeklyValidationData.filter((el) => dateFilter(el.date));
    } else {
      data = weeklyValidationData.filter(
        (el) => READ_STATUS[el.status] === READ_STATUS.PENDING_APPROVAL
      );
    }

    setFilteredData(data);
  }, [
    searchTerm,
    dateRange.startDate,
    dateRange.endDate,
    weeklyValidationData,
  ]);

  const prepareReading = (reading, action) => {
    return reading.map((r) => {
      return {
        "gas_reading_id": r, 
        "action": action, 
        "createdby": username,
      }
    })
  };

  const submitReading = async (params, action) => {
    setLoading(true);
    let alertColor = "success";
    let alertMsg = "Reading submitted successfully";

    try {
      const readingData = Array.isArray(params) ? params : [params];
      const reading = prepareReading(readingData, action);
      await insertGasReadingsActions(client, reading);
      setSelectedRows([]);
    } catch (error) {
      alertColor = "danger";
      alertMsg = error.message.includes(" : ")
        ? error.message.split(" : ")[1]
        : error.message;
    } finally {
      setLoading(false);
      ReactDOM.render(
        <ExpiringAlert color={alertColor} message={alertMsg} />,
        document.getElementById("alert")
      );
    }
  };

  const handlePageChange = (page) => setCurrentPage(page);
  const handleSizePerPageChange = (sizePerPage) => setCurrentSizePerPage(sizePerPage);

  const getHeaders = () => {
    const headers = [];
    for (const col of tableColumns) {
      if (col.dataField) headers.push({ label: col.text, key: col.dataField });
    }
    return headers;
  };

  const handleFileName = () => {
    if (dateRange.endDate) {
      const startDate = new Date(dateRange.startDate).toLocaleDateString();
      const endDate = new Date(dateRange.endDate).toLocaleDateString();
      return `${startDate} ${endDate}.csv`;
    }
    return "WeeklyValidation_Export.csv";
  };

  const tableColumns = columns(submitReading);

  const paginationOptions = paginationFactory({
    custom: true,
    page: currentPage,
    sizePerPage: currentSizePerPage,
    totalSize: filteredData.length,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "75", value: 75 },
      { text: "100", value: 100 },
    ],
    onPageChange: handlePageChange,
    onSizePerPageChange: handleSizePerPageChange,
  });

  const handleSelectRow = (row, isSelect) => {
    if (READ_STATUS[row.status] !== READ_STATUS.PENDING_APPROVAL) return false;

    if (isSelect) {
      setSelectedRows((prevSelected) => [...prevSelected, row.id]);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => id !== row.id)
      );
    }
    return true; // This ensures the checkbox can be toggled
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const pendingRows = rows
        .filter(
          (row) => READ_STATUS[row.status] === READ_STATUS.PENDING_APPROVAL
        )
        .map((row) => row.id);
      setSelectedRows((prevSelected) => [...prevSelected, ...pendingRows]);
    } else {
      const rowsIds = rows.map((row) => row.id);
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => !rowsIds.includes(id))
      );
    }
    return true;
  };

  return (
    <div>
      <Row className='subheader'>
        <Col className='col-8'>
          <h1>Weekly Validation</h1>
        </Col>
      </Row>
      <div className='dashboard-content hartree-hasura-grid'>
        <div className='table-filers-cnt'>
          <div className='table-filers-elements'>
            <Row>
              <Col xs='6' sm='6'>
                <InputGroup className='search'>
                  <Input
                    placeholder='Search by MPRN or Site'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i className='icon ion-ios-search-strong' />
                </InputGroup>
              </Col>
              <Col xs='4' sm='4'>
                <DateRangePicker
                  small
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onDatesChange={(date) => setDateRange(date)}
                  displayFormat={"MM/DD/YYYY"}
                  focusedInput={focus}
                  onFocusChange={(focus) => setFocus(focus)}
                  showClearDates={true}
                  showDefaultInputIcon={true}
                  isOutsideRange={() => false}
                  noBorder={false}
                />
              </Col>
              <Col xs='2' sm='2' className='d-flex justify-content-end'>
                <CSVLink
                  className='export-csv'
                  filename={handleFileName()}
                  data={filteredData.sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                  )}
                  headers={getHeaders()}
                >
                  <Button color='primary'>Download CSV</Button>
                </CSVLink>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <Row>
            <Col xs='auto'>
              <ul className='efficiencyMethodLegend mb-0'>
                <li>
                  <span className='cns'></span> CNS Corrected Consumption
                </li>
                <li>
                  <span className='rd1'></span> RD1 Corrected Consumption
                </li>
              </ul>
            </Col>
            <Col xs='auto'>
              <Button
                color='primary'
                disabled={selectedRows.length === 0}
                onClick={() => setIsConfirmModalOpen(true)}
              >
                Bulk Approve ({selectedRows.length})
              </Button>
            </Col>
          </Row>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <PaginationProvider pagination={paginationOptions}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <div className='d-flex'>
                  <div className='d-flex justify-content-start'>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <PaginationTotalStandalone {...paginationProps} />
                  </div>
                  <div className='d-flex justify-content-end ml-auto'>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
                <BootstrapTable
                  headerClasses='custom-header-class'
                  wrapperClasses='table-responsive'
                  keyField='id'
                  columns={tableColumns}
                  data={filteredData}
                  defaultSorted={[
                    {
                      dataField: "date",
                      order: "desc",
                    },
                  ]}
                  noDataIndication={() => <div>No records in table</div>}
                  remote={{
                    filter: true,
                    pagination: false,
                    sort: false,
                    cellEdit: false,
                  }}
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: false,
                    selected: selectedRows,
                    onSelect: handleSelectRow,
                    onSelectAll: handleSelectAll,
                    nonSelectable: filteredData
                      .filter(
                        (row) =>
                          READ_STATUS[row.status] !==
                          READ_STATUS.PENDING_APPROVAL
                      )
                      .map((row) => row.id),
                    selectionHeaderRenderer: ({ mode, ...rest }) => (
                      <CustomSelectionHeader mode={mode} {...rest} />
                    ),
                    selectionRenderer: ({
                      checked,
                      disabled,
                      onChange,
                      row,
                    }) => (
                      <SelectionCell
                        row={row}
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                      />
                    ),
                    style: { backgroundColor: "#F7F9F9" },
                  }}
                  {...paginationTableProps}
                />
              </div>
            )}
          </PaginationProvider>
        )}

        {/* Modals */}
        <BulkApproveConfirmModal
          isOpen={isConfirmModalOpen}
          toggle={() => setIsConfirmModalOpen(false)}
          onConfirm={() => {
            setIsConfirmModalOpen(false);
            submitReading(selectedRows, ReportActions['Approve']);
          }}
          selectedCount={selectedRows.length}
        />
      </div>
    </div>
  );
};

export default withApollo(WeeklyValidation);
