import React from 'react';
import {graphql, withApollo} from "react-apollo";
import gql from "graphql-tag";
import {withRouter} from 'react-router';
import {Query} from 'react-apollo';
import i18n from '../../views/Pages/Login/i18n';
import Loading from "../../components/Loading"
import {getBusinessPartnerQuery} from "../../queries/Queries";
import BasicQueryComponent from "../BasicQueryComponent";
import _ from "underscore";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';
import CreateTask from "../../views/CreateTask";
import loadable from "@loadable/component";


const CreateReading = loadable(() => {
        return import("./../../" + window.config.consul.CLIENT + "/views/CreateReading/CreateReading")
            .catch(() => import("./../../views/CreateReading/CreateReading")
                .catch(() => import("./../../views/Missing")))
    }
)

const CreateNominationModal = loadable(() => {
    return import("../../" + window.config.consul.CLIENT + "/views/CreateNominationModal/CreateNominationModal")
        .catch(() => import("../../views/CreateNominationModal/CreateNominationModal"))
            .catch(() => import("./../../views/Missing"))
    }
)

class BusinessPartnerHeader extends BasicQueryComponent {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.refetchModalQuery = this.refetchModalQuery.bind(this);
        this.state = {
            dropdownOpen: false,
            showCreateReadingModal: false,
            showCreateTaskModal: false,
            showCreateNominationModal: false,
            queryRerunIndicator: false
        };
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleModal(modalState) {
        this.setState({
            [modalState]: ![modalState]
        });
    }

    /**
     * Function to re-fetch the modal query when the read is submitted to handle the once-a-day submission capability.
     * This function is passed as a prop to the CreateReading component.
     * this.state.queryRerunIndicator is used in the key prop of the <Query /> from react-apollo to force a re-fetch of the query.
     */
    refetchModalQuery() {
        this.setState({...this.state, queryRerunIndicator: !this.state.queryRerunIndicator});
    }

    formateDataObject(dataObj) {
        if(dataObj.mDMBPEstateList){
            return{
                ...dataObj,
                estateList:dataObj.mDMBPEstateList.length !=0 ?dataObj.mDMBPEstateList:[],
                meterPoints:dataObj.mDMBPEstateList.length !=0 ?dataObj.mDMBPEstateList.flatMap(s=>s.mDMEstate.mDMMeterPointList):[],
                bpEstateId:dataObj.id?dataObj.id:'',
            }
        }else{
            return {
                ...dataObj,
                estateList: dataObj.accounts.filter(a => a.properties.length).map(a => a.properties[0].site),
                meterPoints: dataObj.accounts.filter(a => a.properties.length).flatMap(a => a.properties.flatMap(s => s.site.meterPoints)),
                bpEstateId: dataObj.accounts.filter(a => a.properties.length).reduce((acc, a) => (acc[a.properties[0].site.name] = a.properties[0].id, acc), {})
            }
        }
    }

    setHistoryPath(data, item) {
		return !!item.additionalPropInPath &&
			Object.hasOwn(data, item.additionalPropInPath)
			? item.path + data[item.additionalPropInPath]
			: item.path;
	}

    render() {
        let variables;
        if (this.props.match.params["variables"]) {
            variables = JSON.parse(this.props.match.params["variables"][this.props.queryKey]);
        } else {
            variables = this.props.variables;
            if (variables) {
                const compiled = _.template(JSON.stringify(variables));
                variables = JSON.parse(compiled(this.props.match.params));
            }
        }
        return (<Query key={this.state.queryRerunIndicator} query={gql`${this.props.queries[this.props.queryKey]}`} variables={variables}>
            {(result) => {
                if (result.loading) return (<Loading />);//<Loading/>;
                if (result.error) return <div>{result.error} </div>;
                const { data } = result;
                if(Object.keys(data).length == 0){
                    this.props.client.query({query: gql`${this.props.queries[this.props.queryKey]}`, variables: variables});
                    return (<Loading />);
                }
                let expr = null;
                if (this.props.expression) {
                    const compiled = _.template(this.props.expression);
                    expr = compiled(this.props.match.params);
                }
                const expression = expr ? jsonata(expr) : null;
                const transformedData = expression ? expression.evaluate(data) : data;
                const dataWithParents = this.setParents(transformedData, []);

                const { children } = this.props;
                const { queries, queryKey, variables, title, ...newProps } = this.props;
                
                const dataObj = this.formateDataObject(this.getFieldValue(data, this.props.dataPath));
                return (
                    <div className='bp busines-parnter-cnt'>
                        <Row className='subheader'>
                            <Col className='col-8'>
                                <h1>{dataObj.name}</h1>
                            </Col>
                            {!this.props.hideQueryBtn &&
                                (this.props.showNewReadingBtnOnly
                                    ? <Col className='col-4'>
                                        <Button className='pull-right' color='primary' 
                                            onClick={() => this.setState({[this.props.items[0].state]: true})}>
                                            <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{'Submit Reading'}
                                        </Button>
                                    </Col>
                                    : <Col className='col-4'>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                                            <DropdownToggle className='pull-right' color='primary'>
                                                <i className="icon ion-plus-round"></i>&nbsp;&nbsp;{i18n.t('header.new')}
                                            </DropdownToggle>
                                            {this.props.items && 
                                                <DropdownMenu>
                                                    {this.props.items.map(i => 
                                                        <DropdownItem 
                                                            onClick={() => i.state 
                                                                ? this.setState({[i.state]: true}) 
                                                                : this.props.history.push(this.setHistoryPath(dataObj, i))
                                                            }
                                                        >
                                                            {i.label}
                                                        </DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            }
                                            {!this.props.items && 
                                                <DropdownMenu>
                                                    <DropdownItem onClick={()=>this.setState({showCreateReadingModal: true})}>Reading</DropdownItem>
                                                    <DropdownItem onClick={()=>this.setState({showCreateTaskModal: true})}>Task</DropdownItem>
                                                    <DropdownItem onClick={()=>this.props.history.push('/start/bp/' + dataObj.id)}>Process</DropdownItem>
                                                    {this.props.showCreateNomination && 
                                                        <DropdownItem onClick={() => this.setState({showCreateNominationModal: true})}>
                                                            Nomination
                                                        </DropdownItem>
                                                    }
                                                </DropdownMenu>
                                            }
                                        </Dropdown>
                                    </Col>
                                )
                            }
                        </Row>
                        
                        <Modal isOpen={this.state.showCreateReadingModal} toggle={() => this.toggleModal("showCreateReadingModal")} size={"large"}  style={{"maxWidth": "500px", "width": "90%"}}>
                            <ModalHeader toggle={() => this.toggleModal("showCreateReadingModal")}>Create Reading</ModalHeader>
                            <ModalBody>
                                <CreateReading
                                    estateList={dataObj.estateList}
                                    toggle={() => this.toggleModal("showCreateReadingModal")}
                                    refetchModalQuery={this.refetchModalQuery}
                                    meterPoints={dataObj.meterPoints}
                                    bpEstateId={dataObj.bpEstateId}/>
                            </ModalBody>
                        </Modal>
                        
                        <CreateTask modalOpen={this.state.showCreateTaskModal} onClose={() => this.toggleModal("showCreateTaskModal")} customer={dataObj.id}/>
                        {this.state.showCreateNominationModal && 
                           <CreateNominationModal
                                isOpen={this.state.showCreateNominationModal}
                                onClose={() => this.toggleModal("showCreateNominationModal")}
                                dataObj={dataObj}
                                billingAccountsList={dataObj.accounts}
                            />
                        }
                    </div>
                );
            }
            }
        </Query>);
            }
}

export default graphql(getBusinessPartnerQuery, {
    name: 'getBusinessPartner',
    options: props => ({
        variables: { startRow: 0, offset: 10, where: "id = '" + props.match.params.id + "'", orderBy: "name" }
    }),
})(withApollo(withRouter(BusinessPartnerHeader)));