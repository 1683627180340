import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import i18n from "../Login/i18n";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { renderInput } from "../../../RenderInput";

const API_URL =
  window.config && window.config.consul && window.config.consul.CORE_URL
    ? window.config.consul.CORE_URL
    : typeof CORE_URL !== "undefined"
    ? CORE_URL
    : "";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["firstName", "lastName"];

  // Updated check to handle both string and boolean true
  const phoneRequired =
    window.config &&
    window.config.consul &&
    (window.config.consul.PHONE_REQUIRED === true ||
      window.config.consul.PHONE_REQUIRED === "true");

  const identifiersCount =
    window.config &&
    window.config.consul &&
    window.config.consul.IDENTIFIERS_COUNT
      ? parseInt(window.config.consul.IDENTIFIERS_COUNT)
      : 1;

  // Add validation for all identifier fields
  for (let i = 0; i < identifiersCount; i++) {
    const fieldName = i === 0 ? "customerID" : `additionalIdentifier${i}`;
    if (!values[fieldName]) {
      errors[fieldName] = i18n.t("newUserRegistration.required");
    }
  }

  if (phoneRequired) {
    requiredFields.push("phone");
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = i18n.t("newUserRegistration.required");
    }
  });

  if (values.phone) {
    if (!/^\+?[1-9]\d{1,14}$/.test(values.phone.replace(/\s+/g, ""))) {
      errors.phone = i18n.t("newUserRegistration.invalidPhone");
    }
  }

  if (values.firstName && values.firstName.length < 2) {
    errors.firstName = i18n.t("newUserRegistration.tooShort");
  }
  if (values.lastName && values.lastName.length < 2) {
    errors.lastName = i18n.t("newUserRegistration.tooShort");
  }

  return errors;
};

const renderPhoneInput = ({ input, meta: { touched, error } }) => (
  <div className='position-relative'>
    <PhoneInput
      {...input}
      country={"gb"}
      preferredCountries={["gb", "ie", "us"]}
      inputStyle={{
        width: "100%",
        height: "38px",
        fontSize: "14px",
        borderRadius: "4px",
        border: "1px solid #e6e6e6",
      }}
      inputClass={touched && error ? "is-invalid" : ""}
      containerClass='mb-3'
      onChange={(value) => input.onChange("+" + value)}
    />
    {touched && error && (
      <div className='invalid-feedback d-block'>{error}</div>
    )}
  </div>
);

const NewUserRegistration = ({
  handleSubmit,
  invalid,
  submitting,
  location,
  history,
  initialize,
}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [registrationConfig, setRegistrationConfig] = useState(null);
  const [details, setDetails] = useState("");

  const initializeForm = () => {
    if (location && location.state && location.state.email) {
      setTimeout(() => {
        initialize({
          email: location.state.email,
        });
      }, 0);
    } else {
      history.replace("/email-registration");
    }
  };

  const loadConfig = () => {
    try {
      // Updated check to handle both string and boolean true
      const phoneRequired =
        window.config &&
        window.config.consul &&
        (window.config.consul.PHONE_REQUIRED === true ||
          window.config.consul.PHONE_REQUIRED === "true");

      setRegistrationConfig({
        phoneRequired,
      });
    } catch (err) {
      setError(i18n.t("newUserRegistration.configError"));
    }
  };

  useEffect(() => {
    initializeForm();
    loadConfig();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      const identifiersCount =
        window.config &&
        window.config.consul &&
        window.config.consul.IDENTIFIERS_COUNT
          ? parseInt(window.config.consul.IDENTIFIERS_COUNT)
          : 1;

      const identifiers = [];
      for (let i = 0; i < identifiersCount; i++) {
        const fieldName = i === 0 ? "customerID" : `additionalIdentifier${i}`;
        if (values[fieldName]) {
          identifiers.push(values[fieldName].trim());
        }
      }

      const requestBody = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        phoneNumber: values.phone ? values.phone.trim() : "",
        identifiers: identifiers,
        email: values.email.trim(),
      };

      const response = await axios({
        method: "put",
        url: `${API_URL}/register?username=${encodeURIComponent(
          values.email.trim()
        )}`,
        data: requestBody,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response && response.status === 200) {
        setSuccess(true);
        return;
      }
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response;
        const errorMessage =
          (errorResponse.data && errorResponse.data.message) ||
          errorResponse.data ||
          "";

        if (errorResponse.status === 400) {
          setError(i18n.t("newUserRegistration.essenceValidationError"));
          setDetails(errorMessage);
          return;
        }

        if (
          errorResponse.status === 500 &&
          errorMessage.includes("User already exists")
        ) {
          setError(i18n.t("newUserRegistration.userExistsError"));
          setDetails(errorMessage);
          return;
        }

        if (
          errorResponse.status === 500 &&
          errorMessage.includes("Identifiers size does not match")
        ) {
          setError(i18n.t("newUserRegistration.invalidIdentifiersError"));
          setDetails(errorMessage);
          return;
        }
      }

      setError(i18n.t("newUserRegistration.genericError"));
      setDetails(error.message || "An unexpected error occurred");
    }
  };

  if (!registrationConfig) {
    return <div>Loading...</div>;
  }

  if (success) {
    return (
      <div className='app flex-row align-items-center'>
        <Container fluid={true}>
          <Row className='justify-content-center'>
            <Col md='12' style={{ paddingLeft: 0, paddingRight: 0 }}>
              <CardGroup>
                <Card className='p-4 login-panel' style={{ height: "auto" }}>
                  <CardBody className='credentials-card'>
                    <div className='login-logo'></div>
                    <h1>{i18n.t("newUserRegistration.successTitle")}</h1>
                    <p className='signin-text-muted'>
                      {i18n.t("newUserRegistration.successMessage")}
                    </p>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className='app flex-row align-items-center'>
      <Container fluid={true}>
        <Row className='justify-content-center'>
          <Col md='12' style={{ paddingLeft: 0, paddingRight: 0 }}>
            <CardGroup>
              <Card className='p-4 login-panel' style={{ height: "auto" }}>
                <CardBody className='credentials-card'>
                  <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='login-logo'></div>
                    <h1>{i18n.t("newUserRegistration.title")}</h1>
                    <p className='signin-text-muted'>
                      {i18n.t("newUserRegistration.subtitle")}
                    </p>

                    {error && (
                      <Alert
                        color='danger'
                        className='login-err'
                        style={{
                          maxHeight: "100px",
                          overflowY: "auto",
                          marginBottom: "20px",
                        }}
                      >
                        {error}
                        {details && (
                          <div className='mt-2'>
                            <small>{details}</small>
                          </div>
                        )}
                      </Alert>
                    )}

                    <FormGroup>
                      <Label for='firstName' className='label-name'>
                        {i18n.t("newUserRegistration.firstName")}*
                      </Label>
                      <Field
                        inputGroup={{ className: "mb-3" }}
                        name='firstName'
                        component={renderInput}
                        type='text'
                        placeholder={i18n.t(
                          "newUserRegistration.firstNamePlaceholder"
                        )}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for='lastName' className='label-name'>
                        {i18n.t("newUserRegistration.lastName")}*
                      </Label>
                      <Field
                        inputGroup={{ className: "mb-3" }}
                        name='lastName'
                        component={renderInput}
                        type='text'
                        placeholder={i18n.t(
                          "newUserRegistration.lastNamePlaceholder"
                        )}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for='email' className='label-name'>
                        {i18n.t("newUserRegistration.emailAddress")}
                      </Label>
                      <Field
                        name='email'
                        component={(field) => (
                          <input
                            {...field.input}
                            type='email'
                            className='form-control mb-3'
                            disabled
                            readOnly
                          />
                        )}
                      />
                    </FormGroup>

                    {(() => {
                      const identifiersCount =
                        window.config &&
                        window.config.consul &&
                        window.config.consul.IDENTIFIERS_COUNT
                          ? parseInt(window.config.consul.IDENTIFIERS_COUNT)
                          : 1;
                      return Array.from({ length: identifiersCount }).map(
                        (_, index) => (
                          <FormGroup key={index}>
                            <Label
                              for={
                                index === 0
                                  ? "customerID"
                                  : `additionalIdentifier${index}`
                              }
                              className='label-name'
                            >
                              {index === 0
                                ? i18n.t("newUserRegistration.customerID")
                                : i18n.t(
                                    "newUserRegistration.additionalIdentifier"
                                  )}
                              *
                            </Label>
                            {index === 0 && (
                              <div
                                className='mb-2'
                                style={{
                                  maxWidth: "300px",
                                  marginTop: "0.5rem",
                                }}
                              >
                                <small
                                  className='form-text'
                                  style={{ color: "#555" }}
                                >
                                  {i18n.t("newUserRegistration.customerIDHelp")}
                                </small>
                              </div>
                            )}
                            <Field
                              inputGroup={{ className: "mb-3" }}
                              name={
                                index === 0
                                  ? "customerID"
                                  : `additionalIdentifier${index}`
                              }
                              component={renderInput}
                              type='text'
                              placeholder={
                                index === 0
                                  ? i18n.t(
                                      "newUserRegistration.customerIDPlaceholder"
                                    )
                                  : i18n.t(
                                      "newUserRegistration.additionalIdentifierPlaceholder"
                                    )
                              }
                            />
                          </FormGroup>
                        )
                      );
                    })()}

                    {registrationConfig.phoneRequired && (
                      <FormGroup>
                        <Label for='phone' className='label-name'>
                          {i18n.t("newUserRegistration.phoneNumber")}*
                        </Label>
                        <Field name='phone' component={renderPhoneInput} />
                      </FormGroup>
                    )}

                    <Row>
                      <Col xs='12' className='mt-2'>
                        <Button
                          color='primary'
                          className='px-4 sign-in w-100'
                          disabled={invalid || submitting}
                          block
                        >
                          {submitting && (
                            <span>
                              <i className='fa fa-circle-o-notch fa-spin'></i>{" "}
                            </span>
                          )}
                          {i18n.t("newUserRegistration.completeRegistration")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const NewUserRegistrationForm = reduxForm({
  form: "newUserRegistration",
  validate,
})(NewUserRegistration);

export default React.forwardRef((props, ref) => (
  <NewUserRegistrationForm {...props} />
));
